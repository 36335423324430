.react-tooltip {
  position: absolute !important;
  border-radius: 4px !important ;

  padding: 8px 10px !important;
  font-size: 12px !important;
  font-family: 'Open Sans', sans-serif !important;

  background-color: #181C32 !important;
}
