@supports (padding-top: constant(safe-area-inset-top)) {
  body {
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom)
      env(safe-area-inset-left);
  }
}

@media (display-mode: fullscreen) {
  body {
    padding: 0;
  }
}

@media (display-mode: standalone) {
  body {
    padding: 0;
  }
}

/* Older browsers (which don't support CSS variables) */
body {
  height: 100%;
}

/* Browsers which partially support CSS variables (iOS 11.0 – 11.2) */
@supports (padding-top: constant(safe-area-inset-top)) {
  body {
    --safe-area-inset-top: constant(safe-area-inset-top);
    height: calc(100% + var(--safe-area-inset-top));
  }
}
/* Adapted from https://medium.com/@draganeror/iphone-x-layout-features-with-css-environment-variables-d57423433dec */
/* Browsers which fully support CSS variables (iOS 11.2+) */

@supports (padding-top: env(safe-area-inset-top)) {
  body {
    --safe-area-inset-top: env(safe-area-inset-top);
    height: calc(100% + var(--safe-area-inset-top));
  }
}

@media (display-mode: fullscreen) {
  body {
    height: 100%;
  }
}

@media (display-mode: standalone) {
  body {
    height: 100%;
  }
}
