.switch[type='checkbox'] {
  width: 0;
  height: 0;
  visibility: hidden;
}

.switchLabel {
  display: block;
  width: 38px;
  min-width: 38px;
  height: 26px;
  background-color: #e4e6ef;
  border-radius: 100px;
  position: relative;
  cursor: pointer;
  transition: 0.5s;
}

.switchLabel::after {
  content: '';
  width: 20px;
  height: 20px;
  background-color: #fff;
  position: absolute;
  border-radius: 70px;
  top: 3px;
  left: 3px;
  transition: 0.5s;
}

.switchLabel.big {
  width: 44px;
  height: 32px;
}

.switchLabel.big::after {
  left: 4px;
  width: 26px;
  height: 26px;
}

.switch:checked + label:after {
  left: calc(100% - 23px);
}

.switch:checked + label.big:after {
  left: calc(100% - 29px);
}

.switch:checked + label {
  background-color: #009ef7;
}
